import React from "react"
import Form from './Form';
import ContactHero from './ContactHero';
import FormHubspot from "./FormHubspot";
//import ContactHero from './ContactHero';

export default function ContactMain({ children, data }) {
  const contactMain = {
    content: <p>Hello, we are happy you found us. Get in touch.</p>
  }
  return (<>
    <ContactHero data={contactMain}/>
    {/* <Form data={data}/> */}
		<FormHubspot />
  </>);
}
