import React from "react"

export default function ContactHero({data }) {
  return (
  <div className="contact-hero">
    <div className="contact-hero_content">
        {data.content}
    </div>
  </div>
  );
}
