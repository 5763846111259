import React from "react";
import FormLabel from './FormLabel'

export default function Input (props) {
  //check for labelid, if not set then give it a random unqiue id and pass it to the label as well 
  //as the label prop and error messages.
	var labelID = ''
		if (props.labelID !== '') {
		  labelID = props.labelID
		}
		else {
			labelID = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
		}
		const labelData ={
			labelId: labelID,
			label: props.label,
			errorMessage: props.errors
		}

  //checks for a label and renders it only if set, also checks for required to be set as true or not
  return (
  <div className="input-field">
    {(props.label) && ( 
      <FormLabel data={labelData} />
    )}
    {(props.required === true) && ( 
      <input type="text" id={labelID} required name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}   onInvalid={e => e.target.setCustomValidity('Invalid')}
        onInput={e => e.target.setCustomValidity('')}/>
    )}
     {(props.required !== true ) && ( 
      <input type="text" id={labelID} name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}  />
    )}
    
  </div>
  );
}