import React, { Component } from 'react';
import Input from "../controls/Input";
import Select from "../controls/Select";
import TextArea from "../controls/TextArea";
import CountryList from "./CountryList";
import SubmitArrow from "../../images/svg/submit-arrow.svg"
import ContactHero from './ContactHero';

import { useState } from 'react';
import { send } from 'emailjs-com';

//declare the class and the state for each input(both the actual value field and validation) Ex. fname: '' & fnameValid: false
class Form extends Component {
  constructor (props) {
    super(props);
    this.state = {
      fname: '',
			lname: '',
      email: '',
			reason: 'General Inquiry',
      country: 'United States',
      message: '',
      formErrors: {fname: '',lname: '', email: '', reason: '', country: '', message: ''},
      fnameValid: false,
			lnameValid: false,
      emailValid: false,
			reasonValid: false,
      countryValid: false,
      messageValid: false,
      formValid: false,
      submitted: false,
      generalOpen: false,
      locOpen: false,
    }
  }
//check for userinput, and set the state for that value, if undefined refers to select fields because we use a custom dropdown
//vs a normal select, so it gets the value from the innerhtml and name from class, sets and passesit to the state as normal
  handleUserInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (e.target.value !== undefined) {
      name = e.target.name;
      value = e.target.value;
      this.setState({[name]: value});
    }
    else{
      name = e.target.classList[0];
      value = e.target.innerHTML;
      this.setState({[name]: value});
    }
  }
  
  //when the submit button is clicked, or the key enter is pressed, handle the submit, which goes through each validation and 
  // runs validate field
	handleSubmit = (e,formErrors) => {
		for (var key in this.state.formErrors) {
			if (this.state.formErrors.hasOwnProperty(key)) {
				this.validateField(key, this.state[key])
			}
		}
    if(this.state.formErrors.fname === '' && this.state.formErrors.lname === '' && this.state.formErrors.email === ''){
      const isBrowser = typeof window !== "undefined"
      if(isBrowser){
        const reason = document.getElementById("reason").innerHTML
        const fname = document.getElementById("fname").value
        const lname = document.getElementById("lname").value
        const email = document.getElementById("email").value
        const country = document.getElementById("country").innerHTML
        const message = document.getElementById("message").children[0].value
        e.preventDefault()
        send(
              'service_7rwhpto',
              'template_6lpdfk8',
              {
              f_reason: reason,
              f_fname: fname,
              f_lname: lname,
              f_email: email,
              f_country: country,
              f_message: message 
              },
              'FekO4aGmgo2p-EXV0'
            )
              .then((response) => {
              })
              .catch((err) => {
              });
        // window.location.href = "mailto:hello@public-school.com?subject=Public School -contact&body=reason%3d" +reason + "%0D%0Afname%3D" + fname + "%0D%0Alname%3D" + lname +"%0D%0Aemail%3D" + email + "%0D%0Acountry%3D" + country + "%0D%0Amessage%3D" + message +"%0D%0A%0D%0A"
        this.setState({submitted: true});
        
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }    
    }
  }
  componentDidMount(e) {
    const isBrowser = typeof window !== "undefined"
    if(isBrowser){
      window.addEventListener("click", function(event) {
        const activeList = document.getElementsByClassName('select-dropdown_active')
        if (event.target.classList[0] !== 'selected-option' && event.target.classList[0] !== 'current-option') {
          for (var i = 0; i < activeList.length; i++) {
            activeList[i].classList.remove('select-dropdown_active')
          }
        }
      });
    }
  }
  handleSubmited = (e) => {
    e.preventDefault()
  }
  //validate field takes in our inputvalid states and returns them as true or false based off the validation we set
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let fnameValid = this.state.fnameValid;
    let emailValid = this.state.emailValid;
    let lnameValid = this.state.lnameValid;
    let reasonValid = this.state.reasonValid;
    let countryValid = this.state.countryValid;
    let messageValid = this.state.messageValid;
  
  //here we set the validation requirements passed on what the input will be
  //declare error messages here and they will be passed to Form Errors on submit if invalid and display next to label
    switch(fieldName) {
      case 'fname':
        fnameValid = value.length >= 1;
        fieldValidationErrors.fname = fnameValid ? '' : 'First Name is Required';
        break;
			case 'lname':
       lnameValid = value.length >= 1;
        fieldValidationErrors.lname = lnameValid ? '' : 'Last Name is Required';
        break;
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
        break;
			case 'reason':
        reasonValid = value.length >= 1;
        fieldValidationErrors.reason = reasonValid ? '' : 'Contact reason is invalid';
        break;
      case 'country':
        countryValid = value.length >= 1;
        fieldValidationErrors.country = countryValid ? '' : 'Please select a country';
        break; 
      case 'message':
        messageValid = value.length >= 0;
        fieldValidationErrors.message = messageValid ? '' : 'A message is required';
        break;    
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    fnameValid: fnameValid,
										lnameValid: lnameValid,
                    emailValid: emailValid,
                    reasonValid: reasonValid,
                    countryValid: countryValid,
                    messageValid: messageValid,
                  }, this.validateForm);
  }
  //if the validation is met in all the specificied areas, return the form as valid.
  validateForm() {
    this.setState({formValid: this.state.fnameValid && this.state.lnameValid && this.state.emailValid && this.state.reasonValid && this.state.countryValid && this.state.messageValid});
  }
  //this is used to add a class to the inputs with errors after submiting, to help visiually show what needs to be fixed
  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
 //render and return all the input,select, and text area components
  render () {
    const contactMain = {
      content: <p>Hello, we are happy you found us. Get in touch.</p>
    }
    const contactSubmit = {
      content: <p>Thank you — we’ve received your message. We’ll be in touch soon.</p>
    }
    if (!this.state.submitted) {
    return (
      <>
      <ContactHero data={contactMain}/>
       <form id="contact-form" className={this.state.submitted ? 'contact-form form-submitted': 'contact-form'} onSubmit={this.handleSubmited} method="post" encType="text/plain" target="_blank"> 
        <div className={`form-group ${this.errorClass(this.state.formErrors.reason)}`}>
					<Select name="reason" labelID="reason" label="CONTACT REASON*" options={['General Inquiry', 'New Business', 'Career']} value={this.state.reason} onChange={this.handleUserInput} errors={this.state.formErrors.reason} required={true}/>
				</div>
				<div className="form-grid">
					<div className={`form-group ${this.errorClass(this.state.formErrors.fname)}`}>
						<Input name="fname" labelID="fname" label="FIRST NAME*"  value={this.state.fname} onChange={this.handleUserInput} errors={this.state.formErrors.fname} required={true}/>
					</div>
					<div className={`form-group ${this.errorClass(this.state.formErrors.lname)}`}>
						<Input name="lname" labelID="lname" label="LAST NAME*"  value={this.state.lname} onChange={this.handleUserInput} errors={this.state.formErrors.lname} required={true}/>
					</div>
					<div className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
						<Input name="email" labelID="email" label="EMAIL*" value={this.state.email} onChange={this.handleUserInput} errors={this.state.formErrors.email} required={true}/>
					</div>
					<div className={`form-group ${this.errorClass(this.state.formErrors.country)}`}>
						<Select name="country" labelID="country" label="COUNTRY*" options={CountryList} value={this.state.country} onChange={this.handleUserInput} errors={this.state.formErrors.country} required={true}/>
					</div>
				</div>

        <div className={`form-group ${this.errorClass(this.state.formErrors.message)}`}>
					<TextArea name="message" labelID="" label="" placeholder="Please type your message here..." value={this.state.message} onChange={this.handleUserInput} errors={this.state.formErrors.message} required={false}/>
				</div>

        <button type="submit" className="submit-btn" onClick={this.handleSubmit}>Submit<SubmitArrow/></button>
      </form>
      </>
    )
  }
  //returning the success page
  else{
     return (<div className="contact-success">
      <ContactHero data={contactSubmit}/>
     </div>)
  }
  }
}

export default Form;