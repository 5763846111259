import * as React from "react"


export default function FormLabel({ data }) {
  return (
  <div className="input-label">
    <label htmlFor={data.labelID}>{data.label}</label>
    <span>{data.errorMessage}</span>
  </div>
  );
}
