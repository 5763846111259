import React, { Component } from 'react';
import FormLabel from './FormLabel'
import SelectArrow from "../../images/svg/select-arrow.svg"

class Select extends Component {
 constructor (props) {
    super(props);
    this.state = {
      active: false
    }
  }
  handleSelect = (e) => {
     const currentState = this.state.active;
      this.setState({ active: !currentState });
  }
  
render(){
  //check for labelid, if not set then give it a random unqiue id and pass it to the label as well 
  //as the label prop and error messages.
  var labelID = ''
		if (this.props.labelID !== '') {
		  labelID = this.props.labelID
		}
		else {
			labelID = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
		}
		const labelData ={
			labelId: labelID,
			label: this.props.label,
			errorMessage: this.props.errors
		}
    
  //checks for a label and renders it only if set
  return (
    <div className={this.state.active ? 'form-select select-dropdown_active': 'form-select'} >
    {(this.props.label) && ( 
      <FormLabel data={labelData} />
    )}
    <div className="select-dropdown" >
      <div className="selected-option" onClick={this.handleSelect}><span id={labelData.labelId} className="current-option">{this.props.value}</span><SelectArrow/></div>
      <div className="dropdown-content" onClick={this.handleSelect}>
       {this.props.options.map(value => (
         <span key={value} value={value} className={this.props.name} onClick={this.props.onChange} >
          {value}
        </span>
      ))}
      </div>
    </div>
  </div>
  );
}
}

export default Select;