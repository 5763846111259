import React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet';
import ContactMain from '../components/contact/ContactMain'
import Mouse from '../components/mouse/Mouse'

const ContactTemplate = props => {
    return (
      <Layout data={props} pageID={'page-contact'}>
        <Helmet>
          <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
          <meta charSet="utf-8" />
          <title>Contact - Public School</title>
          <meta name="description" content="We are a creative studio that helps brands find their way through new audiences and culture." />
          <meta name="referrer" content="origin" />
        </Helmet>

        <section className="container contact-page">
          <ContactMain data={props}/>
        </section>
        
        <Mouse />

      </Layout>
    )
}

export default ContactTemplate

