import React from 'react'
import HubspotForm from 'react-hubspot-form'


const FormHubspot = () => {
    return (
        <div id="contact-hubspot-form">
        <HubspotForm
            portalId='39998561'
            formId="c51c77ed-1791-4e11-a527-8b9ccd4fe270"
            css=''
        />
        </div>
    )
}

export default FormHubspot